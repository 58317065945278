<template>
  <div>
    <b-table
      ref="table"
      show-empty
      small
      :items="getDevices"
      :fields="fields"
      empty-text="Нет отмеченных тестовых устройств"
    >
      <template #cell(hash)="row">
        <div class="nav">
          <router-link :to="{path: '/users/?device_hash=' + row.item}" class="nav-item" title="Найти пользователя устройства">
            {{ row.item }}
          </router-link>
        </div>
      </template>
      <template v-slot:cell(actions)="data">
        <b-button-group>
          <b-button variant="outline-danger" size="sm" title="Удалить из списка" @click="showConfirm(data.index)">
            <b-icon icon="trash" aria-hidden="true" />
          </b-button>
        </b-button-group>
      </template>
    </b-table>
    <b-modal
      id="modal-delete"
      title="Удаление тестового устройства"
      @ok="deleteTestDevice(index)"
    >
      <p>Удалить устройство из списка отмеченных, как тестовое?</p>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'TestDevices',
  data() {
    return {
      fields: [
        {
          key: 'hash',
          label: 'Хеш тестового устройства',
        },
        {
          key: 'actions',
          label: 'Действия',
          thClass: 'text-center align-middle',
          tdClass: 'text-center align-middle',
        },
      ],
      items: [],
      index: undefined,
    }
  },
  mounted() {
    this.$store.dispatch('queryTestDevices')
  },
  methods: {
    showConfirm(index) {
      this.index = index
      this.$bvModal.show('modal-delete')
    },
    async getDevices() {
      this.items =  await this.$store.getters.getTestHashes
      return this.items || []
    },
    async deleteTestDevice(index) {
      await this.$store.dispatch('queryDeleteTestHash', index)
      this.$refs.table.refresh()
    },
  },
}
</script>

<style scoped>
  .nav {
    display: flex;
  }
  .nav-item {
    text-decoration: none;
  }
  .nav-item::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: rgb(243, 85, 85);
    transition: width .3s;
  }
  .nav-item:hover::after {
    width: 100%;
    transition: width .3s;
  }
  .el-button {
    outline: none;
  }
</style>

